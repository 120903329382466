import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Input } from '../js/components/Input';
import { ThemeProvider } from 'styled-components';
import { themeConWeb, themeEnterprise, themeGO } from '../js/themes';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "input"
    }}>{`Input`}</h1>
    <p><inlineCode parentName="p">{`import { Input } from '@foodsby/nutrient'`}</inlineCode></p>
    <p>{`The Input is a helper around the input HTML type. It can take an optional label and ID which will automatically render a label for you. The input is prestyled.`}</p>
    <p>{`Optionally it can be formatted via the format prop, and can take in different props to change how numbers are formatted (credit card, currency, etc). This component is utilizing the `}<a parentName="p" {...{
        "href": "https://github.com/s-yadav/react-number-format"
      }}>{`react-number-format`}</a>{` library for formatting. If you pass in formatting props it will use the formatting component instead.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Input} mdxType="Props" />
    <h2 {...{
      "id": "playground"
    }}>{`Playground`}</h2>
    <Playground __position={1} __code={'<ThemeProvider theme={themeConWeb}>\n  <Input\n    type=\"text\"\n    label=\"Here is a label:\"\n    placeholder=\"Type it here!\"\n    hint=\"This is the hint.\"\n  />\n  <Input type=\"email\" label=\"Email:\" placeholder=\"email@foodsby.com\" />\n</ThemeProvider>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Input,
      ThemeProvider,
      themeConWeb,
      themeEnterprise,
      themeGO
    }} mdxType="Playground">
  <ThemeProvider theme={themeConWeb} mdxType="ThemeProvider">
    <Input type="text" label="Here is a label:" placeholder="Type it here!" hint="This is the hint." mdxType="Input" />
    <Input type="email" label="Email:" placeholder="email@foodsby.com" mdxType="Input" />
  </ThemeProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      