import React from 'react'
import NumberFormat from 'react-number-format'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { InputError } from './InputError'
import { space } from 'styled-system'

const StyledInput = styled.input`
  ${space}
  appearance: none;
  background-color: ${props => props.theme.color.brandPrimaryTint8};
  border-radius: ${props => props.theme.borderRadius};
  border: solid 1px ${props => props.theme.color.greyShade2};
  box-sizing: border-box;
  display: block;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 16px;
  min-height: 40px;
  padding: 0 12px;
  width: 100%;

  &:focus {
    box-shadow: ${props => props.theme.color.boxShadow};
    border: solid 1px ${props => props.theme.color.brandPrimary};
    background-color: ${props => props.theme.color.brandPrimaryTint8};

    &:invalid,
    &.invalid {
      box-shadow: ${props => props.theme.color.boxShadow};
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.color.greyShade2};
    border-color: ${props => props.theme.color.greyShade3};
  }

  &::placeholder {
    opacity: 1;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.color.greyShade3};
  }

  &:not(:placeholder-shown):invalid,
  &.invalid {
    border: solid 1px ${props => props.theme.color.dangerLight};
    box-shadow: none;
  }
  &:invalid {
    box-shadow: none;
  }
`

const StyledLabel = styled.label`
  font-size: 12px;
  margin-bottom: 4px;
  color: ${props => props.theme.color.greyShade5};
`

const Hint = styled.div`
  border-bottom: 1px solid transparent;
  color: ${props => props.theme.color.greyShade5};
  display: block;
  font-size: 12px;
  margin-top: 4px;
  transition: border 0.5s;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`

export const Input = ({
  className,
  classNames = {},
  error,
  hint,
  id,
  isValid,
  label,
  ...rest
}) => {
  const names = cn(className, {
    ...classNames,
    invalid: (isValid != null && !isValid) || error != null
  })
  // checks to see if we should use NumberFormat
  const isFormatted =
    rest.format != null ||
    rest.mask != null ||
    rest.thousandSeparator != null ||
    rest.thousandsGroupStyle != null ||
    rest.decimalScale != null ||
    rest.fixedDecimalScale != null ||
    rest.decimalSeparator != null ||
    rest.prefix != null ||
    rest.suffix != null

  return (
    <>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      {isFormatted ? (
        <NumberFormat
          className={names}
          id={id}
          customInput={StyledInput}
          {...rest}
        />
      ) : (
        <StyledInput className={names} id={id} {...rest} />
      )}
      {hint && <Hint>{hint}</Hint>}
      <InputError>{error}</InputError>
    </>
  )
}
Input.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.object,
  error: PropTypes.node,
  hint: PropTypes.node,
  id: PropTypes.string,
  isValid: PropTypes.bool,
  label: PropTypes.string
}
export default Input
